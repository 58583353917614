import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { theme } from "themes";

// type itemType = {
//   value: string | number;
//   label: string;
// };

// interface Props extends SelectProps {
//   items: itemType[];
// }

export const SelectBox = ({ items, label, ...rest }: any) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        sx={{
          color: theme.colors.black,
          fontFamily: theme.fontFamily.primary,
        }}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          color: theme.colors.black,
          fontFamily: theme.fontFamily.primary,
          "& .MuiOutlinedInput-root": {
            // Target the root of the OutlinedInput component
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors.white, // Change border color to blue on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors.white, // Example for focused state
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors.black, // Set border color to black by default
            },
          },
        }}
        label={label}
        {...rest}
      >
        {items.map((item: any, index: number) => (
          <MenuItem
            sx={{
              color: theme.colors.black,
              fontFamily: theme.fontFamily.primary,
            }}
            value={item.value}
            key={index}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
