import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import {
  requestGetProfile,
  requestUpdateProfile,
  requestCheckoutSessions,
  requestGetPaymentPlan,
  requestGetTokenLimit,
} from "./actions";

const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    updateProfile(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addCase(requestCheckoutSessions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestCheckoutSessions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestCheckoutSessions.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.redirectUrl) {
        const redirectUrl = action.payload?.redirectUrl;
        if (redirectUrl && !/^https?:\/\//i.test(redirectUrl)) {
          console.error("Malformed redirect URL:", redirectUrl);
          // Fix the URL by prepending "https://"
          window.location.href = `https://${redirectUrl}`;
        } else {
          window.location.href = redirectUrl;
        }
      }
      else if (action.payload?.sessionId)
        state.paymentId = action.payload.sessionId;
    });
    builder.addCase(requestGetPaymentPlan.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestGetPaymentPlan.rejected, (state) => {
      state.isLoading = false;
      state.paymentPlans = "";
    });
    builder.addCase(requestGetPaymentPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      const paymentPlans = action.payload?.paymentPlans;
      if (paymentPlans) state.paymentPlans = paymentPlans;
    });
    builder.addCase(requestGetProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestGetProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestGetProfile.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        Object.assign(state, payload);
      }
    });
    builder.addCase(requestUpdateProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestUpdateProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestUpdateProfile.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestGetTokenLimit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(requestGetTokenLimit.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(requestGetTokenLimit.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload?.tokenLimit) state.tokenLimit = payload.tokenLimit;
    });
  },
});

export const { updateProfile } = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
